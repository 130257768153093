<template lang="pug">
div.container
    div(v-if="!totalVisitors")
        loading-spinner(message="Loading visitor count...")
    div(v-if="totalVisitors")
        div.row
            h2 Internal Stats
        div.row
            p Total visitors: {{totalVisitors}}
        div.row
            b-button(href="/api/visitors/csv") Download Visitors as CSV
    hr/
    //- div(v-if="!mcData.name")
            loading-spinner(message="Loading visitor count...")
        div(v-if="mcData.name")
            div.row
                h2 MailChimp Stats
            div.row
                ul
                    li List name: {{mcData.name}}
                    li Member count: {{mcData.stats.member_count}}
                    li Last email sent: {{mcData.stats.date_last_campaign}}
                    li New members since last email: {{mcData.stats.member_count_since_send}}
                    li Unsubscribes since last email: {{mcData.stats.unsubscribe_count_since_send}}
                    li Cleaned emails since last email: {{mcData.stats.cleaned_count_since_send}}
                    li Email open rate: {{mcData.stats.open_rate}}%
        hr/
    div(v-if="!attendanceByEventsChartData")
        loading-spinner(message="Loading event data...")
    div(v-if="attendanceByEventsChartData")
        div.row
            h2 Analytics
        div.row
            div.col-sm-12
                magic-chart(:data="attendanceTotalsChartData" :options="attendanceByEventsChartOptions")
                p Work in progress... It's complicated to visualise overlapping data sets

</template>

<script>
import LineChart from '@/components/LineChart.vue'

export default {
  components: {
    'magic-chart': LineChart
  },
  data: function () {
    return {
      totalVisitors: null,
      attendanceTotalsChartData: null,
      attendanceByEventsChartData: null,
      attendanceByEventsChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 50
              }
            }],
          xAxes: [
            {
              type: 'time',
              stacked: true,
              time: {
                min: '2006-01-01'
              }
            }]
        }
      },
      eventChartData: null,
      mcData: {
        stats: {}
      }
    }
  },
  mounted: function () {
    this.axios.get('/api/visitors/count')
      .then(res => {
        this.totalVisitors = res.data.count
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching visitor count failed',
          text: err.response.data,
          duration: -1
        })
      })

    /* this.axios.get("/api/mailchimp/lists/visitors")
        .then(res => {
            this.totalVisitors = res.data.count
        })
        .catch(err => {
            this.$notify({
                group: "error",
                type: "error",
                title: "Fetching MailChimp data failed",
                text: err.response.data,
                duration: -1
            })
        }) */

    this.axios.get('/api/events')
      .then(res => {
        let attendanceByEvents = {
          datasets: [
            /* {
              label: "Organizer name",
              data: [] // fill with {x: "yyyy-mm-dd", y: Number}
            } */
          ]
        }

        // Parse dates
        for (let event of res.data) {
          event.date = new Date(event.date)
        }

        // Sort events by date
        res.data.sort(function (a, b) { return a.date - b.date })

        // Failed attempt at stacked total attendance chart
        for (let event of res.data) {
          let datapoint = {
            x: null,
            y: null
          }

          // Apply visitor offsets and overrides
          if (event.visitorCountOverride > 0) {
            datapoint.y = event.visitorCountOverride
          } else {
            datapoint.y = event.visitors.length + event.visitorCountOffset
          }

          // Round to first day of month
          datapoint.x = event.date.toISOString().slice(0, 8) + '01'

          // Push to right label...
          let done = false
          for (let dataset of attendanceByEvents.datasets) {
            if (dataset.label === event.organizer) {
              dataset.data.push(datapoint)
              done = true
              break
            }
          }
          // ...or create one
          if (!done) {
            attendanceByEvents.datasets.push({
              label: event.organizer,
              data: [datapoint],
              backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16)
            })
          }

          // attendanceByEvents.datasets[0].data.push(datapoint)
          // attendanceByEvents.datasets[0].data = attendanceByEvents.datasets[0].data.slice(0,4)
        }

        // Push to DOM
        this.attendanceByEventsChartData = attendanceByEvents

        // Total visitors
        let attendanceTotals = {
          datasets: [
            {
              label: 'Total visitors in all events',
              data: [] // fill with {x: "yyyy-mm-dd", y: Number}
            }
          ]
        }

        for (let event of res.data) {
          let datapoint = {
            x: null,
            y: null
          }

          // Apply visitor offsets and overrides
          if (event.visitorCountOverride > 0) {
            datapoint.y = event.visitorCountOverride
          } else {
            datapoint.y = event.visitors.length + event.visitorCountOffset
          }

          // Round to first day of month
          datapoint.x = event.date.toISOString().slice(0, 8) + '01'

          // Combine with previous datapoints for the same month
          let done = false
          for (let oldDatapoint of attendanceTotals.datasets[0].data) {
            if (oldDatapoint.x === datapoint.x) {
              oldDatapoint.x += datapoint.x
              done = true
              break
            }
          }
          // ...or create one
          if (!done) { attendanceTotals.datasets[0].data.push(datapoint) }
        }

        // Push to DOM
        this.attendanceTotalsChartData = attendanceTotals
      })
    /* .catch(err => {
                this.$notify({
                    group: "error",
                    type: "error",
                    title: "Fetching event data failed",
                    text: err.response.data,
                    duration: -1
                })
            }) */
  }
}
</script>
